import sha1 from 'js-sha1'

export function useDirectusImgSrc(uuid, width, height, name = '') {
  const path = useDirectusFile(uuid, name)
  return (
    path +
    '/?format=avif' +
    (width ? `&width=${width}` : '') +
    (height ? `&height=${height}` : '') +
    (height && width ? `&fit=cover` : '')
  )
}

export function useDirectusFile(uuid, name = '') {
  const { $directus } = useNuxtApp()
  const pathBase = `${$directus('csr').url.origin}/assets`
  const nameParam = `/${name}`
  return `${pathBase}/${uuid}${name ? nameParam : ''}`
}

export function useCachedImgSrc(address, suffix = 'medium', format = 'webp') {
  if (address === '') return ''
  try {
    const staticAssetsUrl = useRuntimeConfig().public.staticAssetsUrl
    const hostname = new URL(address).hostname
    const hash = sha1(address)
    const imageSuffix = suffix !== '' ? '_' + suffix : ''
    return `${staticAssetsUrl}/images/${hostname}/${hash}${imageSuffix}.${format}`
  } catch (error) {
    console.error('Error in useCachedImgSrc', error)
    return address
  }
}

export function useManifestSrc(address) {
  if (address === '') return ''
  const staticAssetsUrl = useRuntimeConfig().public.staticAssetsUrl
  try {
    const hostname = new URL(address).hostname
    const hash = sha1(address)
    return `${staticAssetsUrl}/manifests_remote/${hostname}/${hash}/manifest.json`
  } catch (error) {
    console.error('Error in useManifestSrc', error)
    return address
  }
}
